@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Barlow Condensed', sans-serif;
}

.loadingText2:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

.freemintLoading{
  font-size: 25px;
  margin-top: 3%;
  background: -webkit-linear-gradient(#1df739, #b3fe00, #b3fe00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  font-style: italic;
  font-weight: bold;
}

a {
  color: rgb(50, 158, 40);
  cursor: pointer;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
  color: rgb(33, 124, 25);
  cursor: pointer;
}

.bottom{
  display: flex;
 }

.highLights{
  color: #9de2ad;
  text-shadow: 0 0 10px #67d681;
}

.highLights2{
  color: #ffffff;
  text-shadow: 0 0 10px rgba(133, 131, 131, 0.548);
  font-weight: bold;
  background: rgba(55, 103, 233, 0.89);
  padding: 6px;
}

.boxWrap2Story2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/city.1a5a36b8.jpg) no-repeat center center fixed;
  background-size: cover;
}

.submit:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.emailMain {
  background: rgb(244, 244, 244);
  background: linear-gradient(180deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 1) 35%, rgba(255, 255, 255, 1) 100%);
  padding-top: 50px;

}

.osPic3:hover {
-webkit-transform: scale(1.1);
        transform: scale(1.1);
cursor: pointer;
}



@media screen and (max-width: 499px) {
  .socialMain{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 14px;
    justify-content: flex-start;
    padding: 25px;
  }
  
  .socialMain2{
    justify-content: center;
    margin-left: auto;
    margin-right: auto; 
  }
.handshake{
  width: 50px;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}
  .osPic3{
    width: 55px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .email {
    
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    transition: transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 35px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;

  }

  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 50px;
    height: 50px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 83%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .iconsMobile {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;

  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 27px;
    height: 27px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 280px;
    height: 72px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 25px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 95%;
  }

  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 70px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 40px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon3 {
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 18px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;
    margin-top: 25px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 80px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;

    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 20px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 100%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 22px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 16px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: none;

  }

  .mintMain {
    display: flex;
    justify-content: space-between;
    display: flex;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url(/static/media/nigeria.89f9b65f.jpg) no-repeat center center fixed;
    background-size: cover;
    flex-flow: column nowrap;
  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .socialMain{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
  }
  
  .socialMain2{
    justify-content: center;
    margin-left: auto;
    margin-right: auto; 
  }
.handshake{
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}
  .osPic3{
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .email {
    
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;

  }

  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 60px;
    height: 60px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 83%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    display: none;
  }

  .iconsMobile {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 400px;
    height: 103px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 25px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 95%;
  }

  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 70px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 40px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

  }
  .storyCon3 {
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 18px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;
    margin-top: 25px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 110px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;

    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 22px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 100%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 22px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    width: 30%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 18px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: none;
  }

  .mintMain {
    display: flex;
    justify-content: space-between;
    display: flex;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url(/static/media/nigeria.89f9b65f.jpg) no-repeat center center fixed;
    background-size: cover;
    flex-flow: column nowrap;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .socialMain{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
  }
  
  .socialMain2{
    justify-content: center;
    margin-left: auto;
    margin-right: auto; 
  }
.handshake{
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}
  .osPic3{
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .email {
    
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;

  }

  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }

  .connect2Moile {
    display: none;
  }

  .iconsMobile {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 60px;
    height: 60px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 500px;
    height: 129px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 25px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 70px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 40px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon3 {
    font-size: 22px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 25px;
    margin-top: 25px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 110px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }


  .logoF {
    color: white;
    text-align: center;
    font-size: 22px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 100%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 22px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 18px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: none;

  }


  .mintMain {
    display: flex;
    justify-content: space-between;
    display: flex;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url(/static/media/nigeria.89f9b65f.jpg) no-repeat center center fixed;
    background-size: cover;
    flex-flow: column nowrap;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
 
.socialMain{
  color: #0b1016;
  font-size: 16px;
  justify-content: flex-start;
  padding: 25px;
  display: none;
}

.socialMainMob{
  color: #0b1016;
  font-size: 16px;
  justify-content: flex-start;
  padding: 25px;
  
}

.socialMain2{
  justify-content: center;
  margin-left: auto;
  margin-right: auto; 
}
   
.handshake{
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}
  .osPic3{
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .email {
    
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;

  }

  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }

  .connect2Moile {
    display: none;
  }

  .iconsMobile {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 65px;
    height: 65px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 500px;
    height: 129px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 30px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

   .storyCon3 {
    font-size: 25px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }


  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 30px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 140px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 100%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 22px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 18px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: none;

  }


  .mintMain {
    display: flex;
    justify-content: space-between;
    display: flex;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12, 17, 19, 0.33575148809523814) 0%, rgba(12, 17, 19, 0.36656381302521013) 35%, rgba(12, 17, 19, 0.7391128326330532) 100%), url(/static/media/nigeria.89f9b65f.jpg) no-repeat center center fixed;
    background-size: cover;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .socialMain{
    color: #0b1016;
    font-size: 16.8px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;
  }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2{
    justify-content: center;
    
  }
  .osPic3{
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .email {
     justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 25px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    
   }

  .emailCon {
    font-size: 25px;
    text-align: center;
    color: white;

  }
  
.handshake{
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  margin-right: 10px;
}


  input {
    padding: 10px;
    border: 2px solid black;
    border-radius: 7px;
  }

  .connect2Moile {
    display: none;
  }

  .iconsMobile {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 65px;
    height: 65px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
   }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 450px;
    height: 116px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 40px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .ob {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 90px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
   }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 70px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon3 {
    font-size: 25px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 180px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 30px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 900px;
    height: 391px;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 25px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 25px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 85px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 18px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
  */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
  */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 100%;
    min-height: 100%;
    width: 200%;
    height: 200%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }

  .mintMain {
    display: flex;
    justify-content: space-between;

  }

}

@media screen and (min-width: 1920px) {
  .socialMain{
    color: #0b1016;
    font-size: 25px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;

   }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2{
    justify-content: center;
 
  }
  
  .osPic3{
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  
  .handshake{
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 10px;
  }
  .email {
    
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 40px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 35px;
    text-align: center;
    color: white;

  }
  input {
    padding: 20px;
    border: 3px solid black;
    border-radius: 7px;
  }

  .connect2Moile {
    display: none;
  }

  .iconsMobile {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 85px;
    height: 85px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 43px;
    height: 43px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 600px;
    height: 155px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 55px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .ob {
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 135px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 90px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }
  .storyCon3 {
    font-size: 35px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 50px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }


  .intro {
    color: white;
    font-size: 250px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }


  .logoF {
    color: white;
    text-align: center;
    font-size: 40px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 80%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 28px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 35px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 35px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 22px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 85px;
    height: 85px;

    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 85px;
    height: 85px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 110px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 40px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 28px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 75px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 25px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 28px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 55%;
    left: 55%;
    min-width: 200%;
    min-height: 200%;
    width: 110%;
    height: 110%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }

  .mintMain {
    display: flex;
    justify-content: space-between;
  }

}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .socialMain{
    color: #0b1016;
    font-size: 34px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;

   }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  
  .socialMain2{
    justify-content: center;
 
  }
  
  .osPic3{
    width: 130px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  .email {
    
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
   }
   .handshake{
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 15px;
  }
  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 50px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 55px;
    text-align: center;
    color: white;

  }

  input {
    padding: 40px;
    border: 3px solid black;
    border-radius: 7px;
    width: 400px;
  }

  .connect2Moile {
    display: none;
  }

  .iconsMobile {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 115px;
    height: 115px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 45px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 800px;
    height: 207px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 80px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .ob {
    text-align: center;
    font-size: 45px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 170px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;

  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 130px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon3 {
    font-size: 55px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 65px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }


  .intro {
    color: white;
    font-size: 350px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }


  .logoF {
    color: white;
    text-align: center;
    font-size: 60px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 80%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 35px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }


  .loadingText {
    font-size: 45px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 45px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 26px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 105px;
    height: 105px;

    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 105px;
    height: 105px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 55px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 140px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 48px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 20px 25px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 40px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 33px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 40px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 60%;
    left: 60%;
    min-width: 200%;
    min-height: 200%;
    width: 110%;
    height: 110%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }

  .mintMain {
    display: flex;
    justify-content: space-between;
  }

}

@media screen and (min-width: 3840px) {
  
  .socialMain{
    color: #0b1016;
    font-size: 55px;
    justify-content: flex-start;
    padding: 25px;
    margin-top: -50px;

   }
  
  .socialMainMob{
    color: #0b1016;
    font-size: 16px;
    justify-content: flex-start;
    padding: 25px;
    display: none;
  }
  .handshake{
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-right: 15px;
  }
  .osPic3{
    width: 130px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }
  .email {
    background-color: #000000f8;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 20px;
    
   }

  .email2 {
    flex-flow: column nowrap;
    display: flex;
  }

  .submit {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #28a745;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 10px;
    font-size: 60px;
    border-radius: 7px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
   }

  .emailCon {
    font-size: 55px;
    text-align: center;
    color: white;

  }

  input {
    padding: 40px;
    border: 3px solid black;
    border-radius: 7px;
    width: 400px;
  }

  .connect2Moile {
    display: none;
  }

  .iconsMobile {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000f8;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .stickyBtn {
    position: -webkit-sticky;
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    -webkit-animation: floating 2s ease-in-out infinite;
            animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }

  .stickyBtn2 {

    width: 145px;
    height: 145px;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;

    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #fe8b00;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #fe8b00;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #fe8b00;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }




  .osPic {
    width: 75px;
    height: 75px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 65px;
    color: white;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 29%, rgba(255, 255, 255, 1) 59%, rgba(251, 251, 251, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 2%;
    position: relative;
    z-index: 1;
  }

  .video-background {
    background-image: url(/static/media/flag.1e93d648.mp4);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

  }

  .boxWrap2Footer img {
    width: 1100px;
    height: 284px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }



  .boxWrap4 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #1eccc3;
    margin-bottom: -20%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }

  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 110px;
    color: white;
    font-family: 'Barlow Condensed', sans-serif;
    background-color: #28a745;
    padding: 10px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .ob {
    text-align: center;
    font-size: 60px;
    margin-top: 10px;
    color: #1a6e2e;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 200px;
    background: -webkit-linear-gradient(#43dd67, #165825);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .mintName {
    color: rgb(241, 1, 1);
    font-size: 160px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }

  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyCon3 {
    font-size: 70px;
    margin-top: 20px;
    text-align: center;
    color: white;
  }

  .storyCon2-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyCon2 {
    font-size: 100px;
    margin-top: 20px;
    text-align: center;
    color: black;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }


  .intro {
    color: white;
    font-size: 500px;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .3), 0 3px 5px rgba(0, 0, 0, .2), 0 5px 10px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .2), 0 20px 20px rgba(0, 0, 0, .15);
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 85px;
    background: -webkit-linear-gradient(#43dd67, #28a745);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: bold;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;

  }


  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 80%;
    z-index: 1000000000000000000000;
    margin-top: 15vh;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid white;
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 35px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 45px;
    text-align: center;
    color: white;
  }

  .success2 {
    font-size: 45px;
    text-align: center;
    color: white;
    margin-top: 1%;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 26px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Barlow Condensed', sans-serif;
    align-items: center;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Barlow Condensed', sans-serif;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
  }

  .connect div:hover {
    color: white;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 70px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 140px;
    height: 140px;

    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    font-size: 70px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 140px;
    height: 140px;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    background-color: #6cfc19;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 70px;
    border-radius: 50px;
    padding-left: 50px;
    padding-right: 50px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;

    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .nftamount {
    color: white;
    font-size: 180px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 48px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #243136d2;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .button-87 {
    margin: 10px;
    padding: 25px 35px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #017a1d 0%, #2bbe4d 51%, #017a1d 100%);
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 50px;
    font-family: 'Barlow Condensed', sans-serif;
  }

  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .button-87:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
  }

  .totalSupply {
    text-align: center;
    font-size: 140px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;

    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: #2e3d44;
    text-align: center;
    font-size: 40px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 133px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 50px;
    font-family: 'Barlow Condensed', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: white;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 20px;
    background: #af3e33;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    box-shadow: 0 5px #91241b;
    color: white;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {

    text-shadow: 0 0 10px #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;

  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/cover.jpg') no-repeat fixed;
    */
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    position: relative;

  }

  .videoBg2 {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vidCont {
    object-fit: cover;
    width: 100%;
  }

  .cont2 {
    /*background: radial-gradient(circle, rgba(12, 17, 19, 0.3385526085434174) 0%, rgba(12, 17, 19, 0.4590007878151261) 35%, rgba(12, 17, 19, 0.8455554096638656) 100%), url('./assets/1.jpg') no-repeat fixed;
    */
    background-size: cover;
    height: 100vh;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }

  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }

  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .videoBg {
    position: fixed;
    top: 65%;
    left: 65%;
    min-width: 200%;
    min-height: 200%;
    width: 110%;
    height: 110%;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }

  .mintMain {
    display: flex;
    justify-content: space-between;
  }

}
@media screen and (max-width: 499px) {

  .carouselIMG {
    width: 330px;
    height: 220px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 330px;
    height: 198px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/cBg.6d89e68e.png) no-repeat center center fixed;
    background-size: cover;

  }
    
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }
    
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 250px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -28%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .carouselIMG {
    width: 330px;
    height: 220px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 330px;
    height: 198px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/cBg.6d89e68e.png) no-repeat center center fixed;
    background-size: cover;
  } 

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  } 

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -20%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .carouselIMG {
    width: 400px;
    height: 267px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 400px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/cBg.6d89e68e.png) no-repeat center center fixed;
    background-size: cover;
  } 

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  } 

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .carouselIMG {
    width: 400px;
    height: 267px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 400px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/cBg.6d89e68e.png) no-repeat center center fixed;
    background-size: cover;
  } 

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  } 

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .carouselIMG {
    width: 400px;
    height: 267px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 400px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/cBg.6d89e68e.png) no-repeat center center fixed;
    background-size: cover;
  } 

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  } 

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
   }

  .slider2 {
    height: 300px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {

  .carouselIMG {
    width: 400px;
    height: 267px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .carouselIMG2 {
    width: 400px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
  }

   .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(12,17,19,0.33575148809523814) 0%, rgba(12,17,19,0.36656381302521013) 35%, rgba(12,17,19,0.7391128326330532) 100%), url(/static/media/cBg.6d89e68e.png) no-repeat center center fixed;
    background-size: cover;
  } 

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  } 

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
     margin-top: 50px;
   }

  .slider2 {
    height: 350px;
    width: 100%;
    overflow: hidden;
     margin-top: -2%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}
